import { DateTime, Duration } from "luxon";
import styled, { css } from "styled-components";

interface TimeFieldProps {
  time?: DateTime;
  showError?: boolean;
  greyError?: boolean;
  error: string;
}

export const TimeField = ({
  time,
  showError,
  greyError: greyError,
  error,
}: TimeFieldProps): JSX.Element => {
  return !showError && time ? (
    <RecentTimeField at={time} />
  ) : (
    <ErrorField greyError={greyError}>{error}</ErrorField>
  );
};

const RecentTimeField = ({
  at,
  showIcon,
}: {
  at: DateTime;
  showIcon?: boolean;
}): JSX.Element => {
  if (at != null) {
    const timeSinceSeen = DateTime.local().diff(at);
    if (timeSinceSeen < Duration.fromObject({ minutes: 2 })) {
      return (
        <SuccessField
          title={at.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
        >
          {showIcon ? `\u2713` : "< 2min"}
        </SuccessField>
      );
    }
    if (timeSinceSeen < Duration.fromObject({ hours: 1 })) {
      return (
        <SuccessField
          title={at.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
        >
          {showIcon ? `\u2713` : "< 60 min"}
        </SuccessField>
      );
    }
    if (at > DateTime.now().startOf("day")) {
      return (
        <WarningField>
          {showIcon ? `\u2713` : at.toLocaleString(DateTime.TIME_24_SIMPLE)}
        </WarningField>
      );
    }
    return (
      <WarningField>{showIcon ? `\u2713` : at.toLocaleString()}</WarningField>
    );
  }
  return <ErrorField></ErrorField>;
};

interface SignalFieldProps {
  signal?: number;
  type?: string;
  hideForSmallScreens?: boolean;
}

export const SignalField = ({
  signal,
  type,
  hideForSmallScreens,
}: SignalFieldProps): JSX.Element => {
  const signalStrengthThresholds =
    // eslint-disable-next-line no-magic-numbers
    type === "HAN_LTEM" ? [-110, -100] : [-85, -75];

  return signal ? (
    signal > 0 ? (
      <ErrorField hideForSmallScreens={hideForSmallScreens}>—</ErrorField>
    ) : signal > signalStrengthThresholds[1] ? (
      <SuccessField hideForSmallScreens={hideForSmallScreens}>
        {signal}
      </SuccessField>
    ) : signal > signalStrengthThresholds[0] ? (
      <WarningField hideForSmallScreens={hideForSmallScreens}>
        {signal}
      </WarningField>
    ) : (
      <ErrorField hideForSmallScreens={hideForSmallScreens}>
        {signal}
      </ErrorField>
    )
  ) : (
    <ErrorField hideForSmallScreens greyError>
      —
    </ErrorField>
  );
};

const ColoredField = styled.td<{ hideForSmallScreens?: boolean }>`
  font-weight: bold;
  text-align: center;

  ${(props) =>
    props.title &&
    css`
      :hover {
        cursor: help;
      }
    `}

  @media (max-width: 720px) {
    display: ${(props) => props.hideForSmallScreens && "none"};
  }
`;

const SuccessField = styled(ColoredField)`
  color: ${({ theme }): string => theme.colors.success[500]};
`;

const WarningField = styled(ColoredField)`
  color: ${({ theme }): string => theme.colors.warning[500]};
`;

const ErrorField = styled(ColoredField)<{ greyError?: boolean }>`
  color: ${(props) =>
    props.greyError ? "black" : props.theme.colors.danger[500]};
`;
