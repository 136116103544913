/* eslint-disable no-magic-numbers */
import {
  IconButton,
  IconButtonSize,
  IconType,
  Loader,
  NarrowScreen,
} from "components";
import { intermediaryIdToIntermediaryName } from "domain/intermediary";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { IntermediaryId, PermissionScope } from "./Dashboard";
import { DeviceModal } from "./dashboard-device-modal";
import { SearchInput } from "./SearchInput";
import { SignalField, TimeField } from "./ColoredFields";
import {
  ShipmentStatus,
  getDeviceShipmentStatus,
  capitalize,
  getTranslatedActivationStatus,
} from "../../utils";
import { theme } from "../../theme";
import { ButtonAll } from "./ButtonAll";
import { setIsOnlineStatus } from "../../utils/setIsOnlineStatus";
import {
  ActivationStatus,
  DeviceListDeviceFragment,
  DeviceListDeviceFragmentDoc,
  MyIntermediaryDevicesSearchDocument,
  PostcodeCountry,
  SubscriptionType,
} from "generated/graphql/graphql";
import { useLazyQuery } from "@apollo/client";
import { FragmentType, useFragment } from "generated/graphql";

interface Props {
  scopesByIntermediary: Map<IntermediaryId, PermissionScope[]>;
  userPermittedToSeeDeviceNames: boolean;
  activeIntermediaries?: string[];
}

export function DeviceList(props: Props): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const [devices, setDevices] = useState<ShippedDevice[]>([]);
  const [loadAllButton, setLoadAllButton] = useState<boolean>(
    searchParams.get("all") ? false : true
  );

  const [deviceModalOpen, setDeviceModalOpen] = useState<
    ShippedDevice | undefined
  >();

  const onModalClose = () => {
    setDeviceModalOpen(undefined);
  };

  const [
    getMeDeviceSearch,
    { data: meDataDevices, loading: isLoadingDevices },
  ] = useLazyQuery(MyIntermediaryDevicesSearchDocument);

  useEffect(() => {
    let devicesFilteredBySelectedIntermediariesOrAll: FragmentType<
      typeof DeviceListDeviceFragmentDoc
    >[] = [];

    if (meDataDevices) {
      const devicesRaw =
        meDataDevices.me.intermediaries?.flatMap((intermediary) => {
          return (
            (intermediary?.devices as FragmentType<
              typeof DeviceListDeviceFragmentDoc
            >[]) ?? []
          );
        }) ?? [];

      devicesFilteredBySelectedIntermediariesOrAll = props.activeIntermediaries
        ?.length
        ? devicesRaw.filter((device) => {
            const _device = useFragment(DeviceListDeviceFragmentDoc, device);
            return (
              "intermediaryId" in _device &&
              typeof _device.intermediaryId === "string" &&
              props.activeIntermediaries?.includes(_device.intermediaryId)
            );
          })
        : devicesRaw;

      let devicesFilteredBySearchParam =
        devicesFilteredBySelectedIntermediariesOrAll.map(graphqlDeviceToDevice);

      const searchExternalUserId = searchParams.get("externalUserId");
      if (searchExternalUserId != null && searchExternalUserId !== "") {
        devicesFilteredBySearchParam = devicesFilteredBySearchParam.filter(
          (device) =>
            device.ownedBy?.externalUserId === searchExternalUserId ||
            device.shippedTo?.externalUserId === searchExternalUserId
        );
      }

      const searchDeviceId = searchParams.get("deviceId");
      if (searchDeviceId != null && searchDeviceId !== "") {
        devicesFilteredBySearchParam = devicesFilteredBySearchParam.filter(
          (device) => device.deviceId === searchDeviceId
        );
      }

      const searchMeterId = searchParams.get("meterId");
      if (searchMeterId != null && searchMeterId !== "") {
        devicesFilteredBySearchParam = devicesFilteredBySearchParam.filter(
          (device) => device.recentMeter.meterId === searchMeterId
        );
      }

      setDevices(devicesFilteredBySearchParam);
    }
  }, [meDataDevices, props.activeIntermediaries, loadAllButton]);

  useEffect(() => {
    const searchQuery = searchParams.get("query");
    if (searchQuery != null && searchQuery !== "") {
      getMeDeviceSearch({ variables: { search: { query: searchQuery } } });
      return;
    }

    const searchDeviceId = searchParams.get("deviceId");
    if (searchDeviceId != null && searchDeviceId !== "") {
      getMeDeviceSearch({
        variables: { search: { deviceId: searchDeviceId } },
      });
      return;
    }

    const searchMeterId = searchParams.get("meterId");
    if (searchMeterId != null && searchMeterId !== "") {
      getMeDeviceSearch({
        variables: { search: { meterId: searchMeterId } },
      });
      return;
    }

    const searchExternalUserId = searchParams.get("externalUserId");
    if (searchExternalUserId != null && searchExternalUserId !== "") {
      getMeDeviceSearch({
        variables: { search: { externalUserId: searchExternalUserId } },
      });
      return;
    }

    const searchAll = searchParams.get("all");
    if (searchAll != null && searchAll.toLocaleLowerCase() === "true") {
      getMeDeviceSearch({ variables: { filter: props.activeIntermediaries } });
      return;
    }

    setDevices([]);
  }, [searchParams, props.activeIntermediaries]);

  useEffect(() => {
    const searchDeviceId = searchParams.get("deviceId");
    if (searchDeviceId != null && searchDeviceId !== "") {
      setDeviceModalOpen(
        devices.find((device) => device.deviceId === searchDeviceId)
      );
      return;
    }
  }, [devices]);

  return (
    <Col>
      <SearchInput
        disable={isLoadingDevices}
        placeholder="Søk etter enhets-ID, kundenummer, målernummer eller eier"
        searchTerm={searchParams.get("query") ?? ""}
        updateSearchTerm={(term) => setSearchParams(term)}
      />
      {isLoadingDevices ? (
        <NarrowScreen>
          <Loader />
        </NarrowScreen>
      ) : (
        <>
          <DeviceModal
            device={deviceModalOpen}
            open={!!deviceModalOpen}
            scopesByIntermediary={props.scopesByIntermediary}
            onClose={onModalClose}
          />
          {devices.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  {props.scopesByIntermediary.size > 1 && (
                    <IntermediaryNameHeader>Mellomledd</IntermediaryNameHeader>
                  )}
                  <DeviceIdHeader>Enhets-ID</DeviceIdHeader>
                  {props.userPermittedToSeeDeviceNames && (
                    <DeviceNameHeader>Enhetsnavn</DeviceNameHeader>
                  )}
                  <TypeHeader>Type</TypeHeader>
                  <MeterIdHeader>Målernummer</MeterIdHeader>
                  <OwnerHeader>Eier</OwnerHeader>
                  <CustomerIdHeader>Kundenr</CustomerIdHeader>
                  <ActivationStatusHeader>
                    Aktiveringsstatus
                  </ActivationStatusHeader>
                  <LogisticsStatusHeader>Logistikk</LogisticsStatusHeader>
                  <LastActivityHeader>På nett</LastActivityHeader>
                  <HanHeader>HAN-data</HanHeader>
                  <SignalHeader>Sign.</SignalHeader>
                  <DetailHeader>Detaljer</DetailHeader>
                </tr>
              </thead>
              <tbody>
                {devices
                  .sort(sortDevicesByLastSeenNewestOnTop)
                  .sort(sortDevicesByActivationStatusDeactivatedOnBottom)
                  .sort(sortDevicesByReturnStatusReturnedOnBottom)
                  .map((device) => {
                    return (
                      <DeviceRow
                        key={device.deviceId}
                        device={device}
                        scopesByIntermediary={props.scopesByIntermediary}
                        setDeviceModalOpen={setDeviceModalOpen}
                        userPermittedToSeeDeviceNames={
                          props.userPermittedToSeeDeviceNames
                        }
                      />
                    );
                  })}
              </tbody>
            </Table>
          ) : (
            <></>
          )}
          {loadAllButton ? (
            <ButtonAll
              text={"Last inn alle enheter\n(Kan ta tid)"}
              onClick={() => {
                setSearchParams({ all: "true" });
                setLoadAllButton(false);
              }}
            />
          ) : (
            <ButtonAll
              text={"Skjul alle enheter"}
              onClick={() => {
                setSearchParams();
                setLoadAllButton(true);
              }}
            />
          )}
        </>
      )}
    </Col>
  );
}

const hideForTinyScreens = `
  @media (max-width: 400px) {
    display: none;
  }
`;

const hideForSmallScreens = `
  @media (max-width: 720px) {
    display: none;
  }
`;

const IntermediaryNameHeader = styled.th`
  width: 5rem;
  ${hideForSmallScreens}
`;

const DeviceIdHeader = styled.th`
  width: 5.5rem;
`;

const DeviceNameHeader = styled.th`
  width: 5rem;
  ${hideForSmallScreens}
`;

const MeterIdHeader = styled.th`
  width: 10rem;
  ${hideForTinyScreens}
`;

const CustomerIdHeader = styled.th`
  width: 8rem;
  ${hideForSmallScreens}
`;

const LogisticsStatusHeader = styled.th`
  width: 5rem;
  ${hideForSmallScreens}
`;

const ActivationStatusHeader = styled.th`
  width: 8rem;
  ${hideForSmallScreens}
`;

const OwnerHeader = styled.th`
  min-width: 15rem;
  ${hideForSmallScreens}
`;

const LastActivityHeader = styled.th`
  width: 5rem;
`;

const HanHeader = styled.th`
  width: 5rem;
`;

const SignalHeader = styled.th`
  width: 2.25rem;
  ${hideForSmallScreens}
`;

const TypeHeader = styled.th`
  width: 2.25rem;
  ${hideForSmallScreens}
`;

const DetailHeader = styled.th`
  width: 3rem;
`;

const DeviceRow = ({
  device,
  scopesByIntermediary,
  setDeviceModalOpen,
  userPermittedToSeeDeviceNames,
}: {
  device: ShippedDevice;
  scopesByIntermediary: Map<IntermediaryId, PermissionScope[]>;
  setDeviceModalOpen: (device?: ShippedDevice) => void;
  userPermittedToSeeDeviceNames: boolean;
}): JSX.Element => {
  const {
    shipmentStatus,
    deviceId,
    activation,
    name: deviceName,
    intermediaryId,
    lastMessageAt,
    lastSeenAt,
    ownedBy,
    recentMeter,
    signalStrength,
    type,
    shippedAt,
  } = device;

  let fullName = "";
  let externalUserId: string | null | undefined = null;

  if (ownedBy) {
    const { firstName, lastName, externalUserId: userId } = ownedBy;
    fullName = `${firstName} ${lastName}`;
    externalUserId = userId;
  }

  return (
    <tr key={deviceId}>
      {scopesByIntermediary.size > 1 && (
        <IntermediaryNameCell title={intermediaryId}>
          {intermediaryIdToIntermediaryName(intermediaryId)}
        </IntermediaryNameCell>
      )}
      <DeviceIdCell>{deviceId}</DeviceIdCell>
      {userPermittedToSeeDeviceNames && (
        <DeviceNameCell>{deviceName}</DeviceNameCell>
      )}
      <TypeCell>{type === "HAN_LTEM" ? "4G" : "Wifi"}</TypeCell>
      <MeterIdCell>{recentMeter.meterId}</MeterIdCell>
      <OwnerCell>{fullName}</OwnerCell>
      <CustomerIdCell>{externalUserId}</CustomerIdCell>
      <ActivationStatusCell>
        {activation ? getTranslatedActivationStatus(activation?.status) : "❌"}
      </ActivationStatusCell>
      <LogisticsStatusCell>{shipmentStatus}</LogisticsStatusCell>
      <TimeField
        time={lastSeenAt}
        showError={!lastSeenAt}
        error={setIsOnlineStatus(
          shippedAt,
          lastSeenAt,
          lastMessageAt,
          activation?.status
        )}
        greyError
      />
      <TimeField
        time={lastMessageAt}
        showError={!lastMessageAt}
        error="Aldri"
        greyError={!lastMessageAt && !lastSeenAt}
      />
      <SignalField signal={signalStrength} type={type} hideForSmallScreens />
      <DetailCell onClick={() => setDeviceModalOpen(device)}>
        <div className="iconWrapper">
          <IconButton
            type={IconType.ELLIPSE}
            size={IconButtonSize.SMALL}
            color={theme.colors.black[500]}
          />
        </div>
      </DetailCell>
    </tr>
  );
};

const IntermediaryNameCell = styled.td`
  overflow: hidden;
  white-space: nowrap;
  ${hideForSmallScreens}
`;

const DeviceIdCell = styled.td``;
const DeviceNameCell = styled.td`
  ${hideForSmallScreens}
`;

const MeterIdCell = styled.td`
  ${hideForTinyScreens}
`;

const CustomerIdCell = styled.td`
  ${hideForSmallScreens}
`;

const ActivationStatusCell = styled.td`
  ${hideForSmallScreens}
`;

const LogisticsStatusCell = styled.td`
  ${hideForSmallScreens}
`;

const OwnerCell = styled.td`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${hideForSmallScreens}

  ${(props) =>
    props.title &&
    css`
      :hover {
        cursor: help;
      }
    `}
`;

const TypeCell = styled.td`
  ${hideForSmallScreens}
`;

const DetailCell = styled.td`
  background: ${theme.colors.gray[500]} !important;

  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25),
    inset 1px 1px 1px rgba(255, 255, 255, 0.25);
  border-radius: 4px;
  cursor: pointer;

  height: 32px;
  padding: 4px;

  .iconWrapper {
    display: flex;
    justify-content: center;
  }
`;

const sortDevicesByLastSeenNewestOnTop = (
  first: ShippedDevice,
  next: ShippedDevice
): number => {
  if (first == null || first.lastSeenAt == null) {
    return 1;
  }
  if (next == null || next.lastSeenAt == null) {
    // eslint-disable-next-line no-magic-numbers
    return -1;
  }
  // eslint-disable-next-line no-magic-numbers
  return first.lastSeenAt < next.lastSeenAt ? 1 : -1;
};

const sortDevicesByActivationStatusDeactivatedOnBottom = (
  first: ShippedDevice,
  next: ShippedDevice
): number => {
  if (
    first == null ||
    first.activation?.status === ActivationStatus.Deactivated
  ) {
    return 1;
  }
  if (
    next == null ||
    next.activation?.status === ActivationStatus.Deactivated
  ) {
    // eslint-disable-next-line no-magic-numbers
    return -1;
  }
  // eslint-disable-next-line no-magic-numbers
  return 0;
};

const sortDevicesByReturnStatusReturnedOnBottom = (
  first: ShippedDevice,
  next: ShippedDevice
): number => {
  if (first == null || first.shipmentStatus === ShipmentStatus.RETURNED) {
    return 1;
  }
  if (next == null || next.shipmentStatus === ShipmentStatus.RETURNED) {
    // eslint-disable-next-line no-magic-numbers
    return -1;
  }
  // eslint-disable-next-line no-magic-numbers
  return 0;
};

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Table = styled.table`
  border-spacing: 4px 8px;
  border-collapse: separate;
  margin: 0 -4px;
  width: calc(100% + 8px);
  table-layout: fixed;
  color: black;

  thead {
    opacity: 0.5;
    font-weight: 400;
  }

  th {
    color: black;
    font-size: 14px;
    font-weight: 600;
    padding: 2px;
    white-space: nowrap;
  }

  td {
    background: ${({ theme }): string => theme.colors.gray[200]};
    font-size: ${({ theme }): string => theme.fontSizes.small};
    padding: 4px 0.25rem;
    font-weight: 600;
    text-align: center;
  }
`;

export interface ShippedDevice {
  deviceId: string;
  activation?: Activation;
  intermediaryId?: string;
  lastMessageAt?: DateTime;
  lastPingAt?: DateTime;
  lastSeenAt?: DateTime;
  name?: string;
  ownedBy?: DeviceOwner;
  publishInterval?: number;
  returnInfo?: ReturnInfo | null;
  shipmentStatus: ShipmentStatus;
  shippedAt?: DateTime;
  shippedTo?: DeviceRecipient;
  signalStrength?: number;
  type: string;
  subscription: Subscription;
  recentMeter: RecentMeter;
}

interface Subscription {
  subscriptionType: SubscriptionType;
}

interface Activation {
  status: ActivationStatus;
  updatedAt: DateTime;
}

interface RecentMeter {
  meterId: string;
  producer: string;
}

interface BaseDeviceUser {
  firstName: string;
  lastName: string;
  emailAddress?: string | null;
  phoneNumber?: string | null;
  externalUserId?: string | null;
}

export interface DeviceOwner extends BaseDeviceUser {
  postalAddress?: {
    streetAddress?: string | null;
    postcode?: string | null;
    country?: PostcodeCountry | null;
  } | null;
}

export interface DeviceRecipient extends BaseDeviceUser {
  trackingLink?: string | null;
  postalAddress: {
    streetAddress: string;
    postcode: string;
    country: PostcodeCountry;
  };
}

interface ReturnInfo {
  hasBeenUsed?: boolean | null;
  returnedAt?: DateTime;
}

const graphqlDeviceToDevice = (
  graphqlDevice: FragmentType<typeof DeviceListDeviceFragmentDoc>
): ShippedDevice => {
  const {
    deviceId,
    deviceName,
    activation,
    diagnostic,
    intermediaryId,
    ownedBy,
    recentMeter,
    returnInfo,
    shippedAt,
    shippedTo,
    subscription,
    type,
  } = graphqlDevice as DeviceListDeviceFragment;
  const device: ShippedDevice = {
    activation: activation ?? undefined,
    deviceId,
    intermediaryId: intermediaryId ?? undefined,
    name: deviceName ?? undefined,
    ownedBy: ownedBy ?? undefined,
    recentMeter: {
      meterId: recentMeter?.meterId,
      producer: capitalize(recentMeter?.producer.toLowerCase()),
    },
    returnInfo,
    shipmentStatus: getDeviceShipmentStatus(
      returnInfo,
      shippedAt ? DateTime.fromISO(shippedAt) : undefined
    ),
    shippedAt: shippedAt ? DateTime.fromISO(shippedAt) : undefined,
    shippedTo: shippedTo ?? undefined,
    subscription: subscription ?? {
      subscriptionType: SubscriptionType.Personal,
    },
    type,
  };

  if (diagnostic != null && shippedAt != null) {
    const { lastMessageAt, lastPingAt, publishInterval, signalStrength } =
      diagnostic;

    if (lastMessageAt != null && lastMessageAt > shippedAt) {
      device.lastMessageAt = DateTime.fromISO(lastMessageAt);
    }
    if (lastPingAt != null && lastPingAt > shippedAt) {
      device.lastPingAt = DateTime.fromISO(lastPingAt);
    }

    device.lastSeenAt = selectLastSeenAt({
      lastMessageAt: device.lastMessageAt,
      lastPingAt: device.lastPingAt,
    });

    device.publishInterval =
      publishInterval != null ? publishInterval : undefined;

    device.signalStrength =
      signalStrength && device.lastSeenAt ? signalStrength : undefined;
  }

  return device;
};

function selectLastSeenAt({
  lastPingAt,
  lastMessageAt,
}: {
  lastPingAt: DateTime | undefined;
  lastMessageAt: DateTime | undefined;
}): DateTime | undefined {
  if (lastPingAt && lastMessageAt) {
    return lastMessageAt > lastPingAt ? lastMessageAt : lastPingAt;
  } else if (lastMessageAt) {
    return lastMessageAt;
  } else if (lastPingAt) {
    return lastPingAt;
  }
}
